// Initialise dev mode state from cookie
export const useDevModeActive = () => useState<Boolean>('wetix-dev-mode-active', () => useCookie<Boolean>('wetix-dev-mode'))

// useDevMode tools
export const useDevMode = () => {
  // const toast = useToast()
  return {
    active: useDevModeActive(),
    isEnabled: useDevModeActive().value,
    test: () => {
      console.log('test')
    },
    toggle: () => {
      console.log('Toggling dev mode')
      try {
        const cookie = useCookie<Boolean>('wetix-dev-mode')
        const state = useState<Boolean>('wetix-dev-mode-active')
        state.value = !state.value

        cookie.value = state.value
        // toast.add({
        //   color: 'green',
        //   title: `Dev mode ${state.value ? 'enabled' : 'disabled'}`
        // })
      } catch (error) {
        // toast.add({
        //   color: 'red',
        //   title: 'Error toggling dev mode'
        // })
      }
    }
  }
}
